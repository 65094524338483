import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroup,
  Container,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

import { init } from "emailjs-com";
import emailjs from "emailjs-com";
import { USER_ID, TEMPLATE_ID } from "../emailkey";
import Photo from "../../assets/images/user.svg";
import "../../assets/css/HomeHeaders.css";

import FooterDefault from "../Footers/DefaultFooter";
import NavBar from "../Navbar/Navbar";

const LoginPage = (props) => {
  init("user_Fev4Cj8BYMwDhsHHfkxIa");
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [emailForget, setemailForget] = useState("");
  const [password, setPassword] = useState("");
  const [modal, setModal] = React.useState(false);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const handleSubmit = () => {
    emailjs
      .send(
        `service_f9mli5s`,
        "template_1wlgplg",
        {
          sender: emailForget,
        },
        "user_Fev4Cj8BYMwDhsHHfkxIa"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <>
      <NavBar />
      <div className="page-header header-filter" filter-color="black">
        <div className="page-header-image login-back"></div>
        <div className="content">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="5">
                <Card className="card-login card-plain">
                  <Form action="" className="form" method="">
                    <CardHeader className="text-center">
                      <div className="logo-container">
                        <img alt="Logo" src={Photo}></img>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (firstFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons users_circle-08"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email..."
                          type="text"
                          onFocus={() => setFirstFocus(true)}
                          onBlur={() => setFirstFocus(false)}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        ></Input>
                      </InputGroup>
                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (lastFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons objects_key-25"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Mot de passe..."
                          type="password"
                          onFocus={() => setLastFocus(true)}
                          onBlur={() => setLastFocus(false)}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        ></Input>
                      </InputGroup>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button
                        block
                        className="btn-round"
                        color="info"
                        onClick={(e) => props.onLogin(e, [email, password])}
                        size="lg"
                      >
                        Se connecter
                      </Button>
                    </CardFooter>
                    <div className="pull-left">
                      <h6>
                        <a
                          className="link footer-link"
                          href="#inscription"
                          onClick={() => {
                            history.push("/inscription");
                          }}
                        >
                          Créer ma carte
                        </a>
                      </h6>
                    </div>
                    <div className="pull-right">
                      <h6>
                        <a
                          className="link footer-link"
                          href="#login"
                          onClick={() => {
                            setModal(true);
                          }}
                        >
                          Mot de passe oublié ?
                        </a>
                      </h6>
                    </div>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
          <Modal
            isOpen={modal}
            className="modal-lg p-5"
            modalClassName="bd-example-modal-lg"
          >
            <div className="modal-header">
              <br />
              <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={() => setModal(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <input
              type="email"
              className="m-2"
              value={emailForget}
              onChange={(e) => setemailForget(e.target.value)}
            />
            <Button
              className="mx-5"
              onClick={() => {
                handleSubmit();
                setModal(false);
              }}
            >
              Envoyer email
            </Button>
          </Modal>
        </div>
      </div>
      <FooterDefault />
    </>
  );
};

export default LoginPage;
