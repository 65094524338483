import React, { useState } from "react";
import { Card, CardHeader, Button, Input, Form } from "reactstrap";

const InfosChange = (props) => {
  const [firstname, SetFirstname] = useState(props.firstname);
  const [lastname, SetLastname] = useState(props.lastname);
  const [societe, SetSociete] = useState(props.societe);
  const [phone, SetPhone] = useState(props.phone);
  const [address, SetAddress] = useState(props.address);

  return (
    <Form
      onSubmit={(e) =>
        props.onAdd(e, [firstname, lastname, societe, phone, address])
      }
    >
      <Card className="p-3">
        <CardHeader>
          <h1>Informations utilisateurs</h1>
        </CardHeader>
        <Input
          className="mb-1"
          placeholder="Firstname"
          value={firstname}
          onChange={(e) => SetFirstname(e.target.value)}
        />
        <Input
          className="mb-1"
          placeholder="Lastname"
          value={lastname}
          onChange={(e) => SetLastname(e.target.value)}
        />
        <Input
          className="mb-1"
          placeholder="Societe"
          value={societe}
          onChange={(e) => SetSociete(e.target.value)}
        />
        <Input
          className="mb-1"
          placeholder="Phone"
          value={phone}
          onChange={(e) => SetPhone(e.target.value)}
        />
        <Input
          className="mb-1"
          placeholder="Address"
          value={address}
          onChange={(e) => SetAddress(e.target.value)}
        />
        <Button>Update</Button>
      </Card>
    </Form>
  );
};

export default InfosChange;
