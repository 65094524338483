import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import Password from "../../components/Form/Password/Password";
import Mail from "../../components/Form/Mail/Mail";
import Information from "../../components/Form/Informations/Infos";
import Dropdown from "../DropDown/Dropdown";
import NavBarLogin from "../Navbar/NavBarLogin";


class Profil extends Component {
  state = {
    user: {},
  };
  componentDidMount() {
    fetch(
      "https://apicdv.asig-tech.com/auth/infos/" +
        localStorage.getItem("userId"),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Failed to fetch user status.");
        }
        return res.json();
      })
      .then((resData) => {
        this.setState({
          user: resData.user,
        });
      })
      .catch((err) => console.log(err));
  }

  ChangeInfosHandler = (e, data) => {
    fetch(
      "https://apicdv.asig-tech.com/auth/upuser/" +
        localStorage.getItem("userId"),
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstname: data[0],
          lastname: data[1],
          societe: data[2],
          phone: data[3],
          address: data[4],
        }),
      }
    )
      .then((resData) => {
        console.log(resData);
      })
      .catch((err) => console.log(err));
  };
  ChangeMailHandler = (e, data) => {
    fetch(
      "https://apicdv.asig-tech.com/auth/changemail/" +
        localStorage.getItem("userId"),
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: data[0],
          new_email: data[1],
        }),
      }
    )
      .then((resData) => {
        console.log(resData);
      })
      .catch((err) => console.log(err));
  };
  ChangePasswordHandler = (e, data) => {
    fetch(
      "https://apicdv.asig-tech.com/auth/changepwd/" +
        localStorage.getItem("userId"),
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password: data[0],
          new_password: data[1],
        }),
      }
    )
      .then((resData) => {
        console.log(resData);
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      
      <div>
        
        <div className="Menu">
          <Dropdown
            title={this.state.user.firstname}
            username={this.state.user.username}
            poste={this.state.user.poste}
            Onlogout={this.props.logout}
          />
        </div>
        <Container>
          <Row>
            <Col className="col-12 mb-2">
              <Mail onAdd={this.ChangeMailHandler} />
            </Col>

            <Col className="col-12 mb-2">
              <Password onAdd={this.ChangePasswordHandler} />
            </Col>

            <Col className="col-12">
              <Information
                onAdd={this.ChangeInfosHandler}
                societe={this.state.user.societe}
                firstname={this.state.user.firstname}
                lastname={this.state.user.lastname}
                phone={this.state.user.phone}
                address={this.state.user.address}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Profil;
