import React, { Component } from "react";
import { Card } from "reactstrap";
import CDV from "../components/CDV/CDV";

import DropDown from "../components/DropDown/Dropdown";
import FooterDefault from "../components/Footers/DefaultFooter";
import NavBarLogin from "../components/Navbar/NavBarLogin";

import "../assets/css/Cdv_User.css";

class CDV_User extends Component {
  state = {
    user: {},
  };

  componentDidMount() {
    fetch(
      "https://apicdv.asig-tech.com/auth/cdv/" +
        this.props.match.params.username,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Failed to fetch user status.");
        }
        return res.json();
      })
      .then((resData) => {
        this.setState({
          user: resData.user[0],
        });
        console.log(resData.user);
      })
      .catch((err) => console.log(err));
  }
  sendmail = () => {
    let msg =
      "mailto:yourfriend@email.com?subject=Voici ma carte de visite&body=Bonjour, %0D%0A Voici ma carte de visite : http://api.asig-tech.com/#/" +
      this.state.user.username;
    window.location.assign(msg);
  };
  render() {
    let ToolBar = <div></div>;
    if (localStorage.getItem("userId")) {
      ToolBar = (
        <NavBarLogin
          title={this.state.user.firstname}
          username={this.state.user.username}
          Onlogout={this.props.logout}
        />
      );
    }
    return (
      <div>
        {ToolBar}

        <div
          className="cdv_user"
        >
          <CDV
            prod="1"
            logoCompany={`http://51.210.144.111:8080/images/${this.state.user.logoCompany}`}
            profileImage={`http://51.210.144.111:8080/images/${this.state.user.profilePicture}`}
            colorfront={this.state.user.color_principale}
            colorback={this.state.user.colorback}
            colortext={this.state.user.colortext}
            lundi={this.state.user.lundi}
            mardi={this.state.user.mardi}
            mercredi={this.state.user.mercredi}
            jeudi={this.state.user.jeudi}
            vendredi={this.state.user.vendredi}
            samedi={this.state.user.samedi}
            dimanche={this.state.user.dimanche}
            prestations={this.state.user.tarifs}
            expertises={this.state.user.expertises}
            societe={this.state.user.societe}
            firstname={this.state.user.firstname}
            lastname={this.state.user.lastname}
            phone={this.state.user.phone}
            email={this.state.user.email}
            address={this.state.user.address}
            postal_code={this.state.user.postal_code}
            city={this.state.user.city}
            country={this.state.user.country}
            metier={this.state.user.poste}
            website={this.state.user.website}
            instagram={this.state.user.instagram}
            facebook={this.state.user.facebook}
            linkedin={this.state.user.linkedin}
            twitter={this.state.user.twitter}
            username={this.state.user.username}
            sendEmail={this.sendmail}
          />
        </div>
        <FooterDefault />
      </div>
    );
  }
}

export default CDV_User;
