import React from "react";

// reactstrap components
import { Carousel, CarouselItem, CarouselIndicators } from "reactstrap";

/* core components
const items = [
  {
    src: require("../assets/images/factory40.jpg"),
    altText: "Nature, United States"
  },
  {
    src: require("../assets/images/logo.png"),
    altText: "Somewhere Beyond, United States"
  },
  {
    src: require("../assets/images/cdv.jpg"),
    altText: "Yellowstone National Park, United States"
  }
];*/

function CarouselDefault(props) {
  const [items, setItems] = React.useState([]);

  const formatItem = () => {
    let items_ = [];
    console.log(props.carouselPicture)
    if (props.carouselPicture !== undefined) {
      for (let i = 0; i < props.carouselPicture.length; i++)
      {
        items.push({
          src: URL.createObjectURL(props.carouselPicture[0]),
          altText: "Txt1",
        });
      }
        
    }
    setItems(items_)
  };
  
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  
  return (
    <>
      <Carousel activeIndex={activeIndex} next={next} previous={previous} onLoad={formatItem}>
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
          onMouseEnter={formatItem}
        />
        {items.map((item) => {
          return (
            <CarouselItem
              onExiting={onExiting}
              onExited={onExited}
              key={item.src}
            >
              <img src={item.src} alt={item.altText} />
            </CarouselItem>
          );
        })}
        <a
          className="carousel-control-prev"
          data-slide="prev"
          href="#pablo"
          onClick={(e) => {
            e.preventDefault();
            previous();
          }}
          role="button"
        >
          <i className="now-ui-icons arrows-1_minimal-left"></i>
        </a>
        <a
          className="carousel-control-next"
          data-slide="next"
          href="#pablo"
          onClick={(e) => {
            e.preventDefault();
            next();
          }}
          role="button"
        >
          <i className="now-ui-icons arrows-1_minimal-right"></i>
        </a>
      </Carousel>
    </>
  );
}

export default CarouselDefault;
