import React from 'react'

const InfosContact = props => {
    return(
        <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              padding: "10px 10px",
              marginTop: "20px",
            }}
          >
            <div
              className=" info-hover"
              style={{ flexGrow: 1, textAlign: "center" }}
            >
              <div className="icon icon-info" style={{ fontSize: "30px" }}>
                <i class="fas fa-phone"></i>
              </div>
              <h4 className="info-title" style={{ fontSize: "12px" }}>
                {props.phone}
              </h4>
            </div>

            <div
              className=" info-hover"
              style={{ flexGrow: 1, textAlign: "center" }}
            >
              <div className="icon icon-success" style={{ fontSize: "30px" }}>
                <i class="fas fa-at"></i>
              </div>
              <h4 className="info-title" style={{ fontSize: "12px" }}>
                {props.email}
              </h4>
            </div>

            <div
              className=" info-hover"
              style={{ flexGrow: 1, textAlign: "center" }}
            >
              <div
                className="icon icon-warning"
                style={{ fontSize: "30px"}}
              >
                <i class="fas fa-map-marker-alt"></i>
              </div>
              <h4 className="info-title" style={{ fontSize: "12px" }}>
                {props.address}
                <br />
                {props.postal_code}, {props.city} <br />
                {props.country}
              </h4>
            </div>
          </div>
    )
}

export default InfosContact