import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import Photo from "../../assets/images/user.svg";
import CDVList from "./CDVList";
import SearchBar from "./SearchBar";

const Feed = () => {
  const [input, setInput] = useState("");
  const [cdvListDefaukt, setCdvListDefault] = useState();
  const [cdvList, setCdvList] = useState();

  const fetchData = async () => {
    fetch("https://apicdv.asig-tech.com/auth/all", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Failed to fetch user status.");
        }
        return res.json();
      })
      .then((resData) => {
        setCdvList(resData.user);
        setCdvListDefault(resData.user);
      })
      .catch((err) => console.log(err));
  };

  const updateInput = async (input) => {
    const filtered = cdvListDefaukt.filter((country) => {
      return (
        country.firstname.toLowerCase().includes(input.toLowerCase()) ||
        country.lastname.toLowerCase().includes(input.toLowerCase()) ||
        country.societe.toLowerCase().includes(input.toLowerCase())
      );
    });
    setInput(input);
    setCdvList(filtered);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <SearchBar input={input} onChange={updateInput} />
      <CDVList filtered={cdvList} />
    </>
  );
};

export default Feed;
