import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import "./App.css";
import Login from "./components/Auth/LoginPage";
import SignUp from "./components/Auth/SignupPage";
import Profil from "./components/Profil/Profil";

import PasswordForgotten from "./Pages/PasswordOublie";
import EmailVerif from "./Pages/VerifEmail";
import CDVUser from "./Pages/CDV_User";
import Homepage from "./Pages/Homepage";
import { init } from "emailjs-com";
import emailjs from  'emailjs-com';

import { USER_ID, TEMPLATE_ID, SERVICE_ID } from "./components/emailkey"

class App extends Component {
  state = {
    isAuth: false,
    isSignup: false,
    ErrorMessage: [],
  };

  loginHandler = (e, authData) => {
    e.preventDefault();

   
    this.setState({ authLoading: true });
    fetch("https://apicdv.asig-tech.com/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: authData[0],
        password: authData[1],
      }),
    })
      .then((res) => {
        if (res.status === 422) {
          throw new Error("Validation failed.");
        }
        if (res.status !== 200 && res.status !== 201) {
          console.log("Error!");
          throw new Error("Could not authenticate you!");
        }
        return res.json();
      })
      .then((resData) => {
        console.log(resData);
        localStorage.setItem("token", resData.token);
        localStorage.setItem("userId", resData.userId);
        const remainingMilliseconds = 60 * 60 * 1000;
        const expiryDate = new Date(
          new Date().getTime() + remainingMilliseconds
        );
        localStorage.setItem("expiryDate", expiryDate.toISOString());
        this.setAutoLogout(remainingMilliseconds);
        this.setState({ isAuth: true, ErrorMessage: "" });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isAuth: false,
          ErrorMessage: err,
        });
      });
  };

  logoutHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("expiryDate");
    localStorage.removeItem("userId");
    this.setState({ isAuth: false });
  };

  setAutoLogout = (milliseconds) => {
    setTimeout(() => {
      this.logoutHandler();
    }, milliseconds);
  };

  signupHandler = (e, AuthData) => {
    console.log(AuthData);
    e.preventDefault();
    init("user_Fev4Cj8BYMwDhsHHfkxIa");
    emailjs.send(`service_f9mli5s`, "template_g12u93q", {
      mail: AuthData[0],
      to_name: AuthData[2],
    }, "user_Fev4Cj8BYMwDhsHHfkxIa")
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });

    fetch("https://apicdv.asig-tech.com/auth/inscription", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: AuthData[0],
        password: AuthData[1],
        lastname: AuthData[3],
        firstname: AuthData[2],
        societe: AuthData[4],
        phone: AuthData[5],
        address: AuthData[6],
        poste: AuthData[7],
        postal_code: AuthData[8],
        city: AuthData[9],
        country: AuthData[10],
        website: AuthData[11],
        logoCompany: AuthData[12].name,
        profilePicture: AuthData[13].name,
        lundi: AuthData[14],
        mardi: AuthData[15],
        mercredi: AuthData[16],
        jeudi: AuthData[17],
        vendredi: AuthData[18],
        samedi: AuthData[19],
        dimanche: AuthData[20],
        tarifs: AuthData[21],
        color: AuthData[22],
        expertises: AuthData[23],
        instagram: AuthData[24],
        facebook: AuthData[25],
        twitter: AuthData[26],
        linkedin: AuthData[27],
        colorback: AuthData[28],
        colortext: AuthData[29],
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        console.log(AuthData);
        this.setState({
          ErrorMessage: "",
        });
        console.log([AuthData[0], AuthData[1]]);
        alert("Votre carte à bien été créée !")
        console.log(resData);
      }).then(()=> {
        this.loginHandler(e, [AuthData[0], AuthData[1]]);
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isAuth: false,
          ErrorMessage: err,
        });
      });
  };

  RenderSignupForm = () => {
    this.setState({ isSignup: true });
  };

  RenderLoginForm = () => {
    this.setState({ isSignup: false });
  };

  render() {
    return (
      <div>
        {this.state.isAuth ? <Redirect to="/profil" /> : null}
        <Switch>
          <Route
            exact
            path="/verification/:email"
            render={(props) => <EmailVerif  {...props}/>}
          />
          <Route
            exact
            path="/reset/:email"
            render={(props) => <PasswordForgotten {...props}/>}
          />
          <Route
            exact
            path="/inscription"
            render={() => <SignUp onSignup={this.signupHandler} />}
          />
          <Route
            exact
            path="/profil"
            render={() => <Profil logout={this.logoutHandler} />}
          />
          <Route
            exact
            path="/login"
            render={() => <Login onLogin={this.loginHandler} />}
          />
          <Route
            exact
            path="/:username"
            render={(props) => (
              <CDVUser {...props} logout={this.logoutHandler} />
            )}
          />

          <Route
            path="/"
            //render={() => <Login onLogin={this.loginHandler} />}
            render={() => <Homepage />}
          />
        </Switch>
      </div>
    );
  }
}

export default App;
