import React from 'react'
import { Col, Modal, ModalBody, Row } from 'reactstrap'

const ModalContact = props => {

    return(
        <Modal
        isOpen={props.ContactModal}
        className="modal-lg"
        modalClassName="bd-example-modal-lg"
        onClick={() => props.setContactModal(false)}
      >
        <div className="modal-header">
          <br />
          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={() => props.setContactModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody style={{ textAlign: "center" }}>
          <Row>
            <Col md="6">
              <div className="info info-horizontal">
                <div className="icon icon-info">
                  <i className="now-ui-icons tech_mobile"></i>
                </div>
                <div className="description">
                  <h5 className="info-title">Contactez-moi</h5>
                  <p>
                    {props.firstname} {props.lastname} <br></br>
                    +33{props.phone} <br></br>
                    {props.email} <br></br>
                  </p>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="info info-horizontal">
                <div className="icon icon-info">
                  <i className="now-ui-icons location_pin"></i>
                </div>
                <div className="description">
                  <h5 className="info-title">L'adresse du bureau</h5>
                  <p>
                    {props.address} <br></br>
                    {props.postal_code}, {props.city} <br />
                    {props.country}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    )
}

export default ModalContact