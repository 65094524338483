import React, { useEffect } from 'react';

const EmailVerif = (props) => {
    useEffect(() => {
      console.log(props.match.params.email)
        fetch(
            "https://apicdv.asig-tech.com/auth/verifemail/" +
              props.match.params.email,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
            .then((res) => {
              if (res.status !== 200) {
                throw new Error("Failed to fetch user status.");
              }
              return res.json();
            })
            .then((resData) => {
              console.log(resData.message);
            })
            .catch((err) => console.log(err));
    }, [])

    return(
        <div>
            <p>Votre email a été verifié</p>
        </div>
    )
}

export default EmailVerif;