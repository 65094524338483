import React, { useState } from 'react'
import { Button, Modal, ModalBody } from 'reactstrap'
import QRCode from "qrcode.react";

const Share = props => 
{
    return(
        <Modal
        isOpen={props.smallModal}
        className="modal-sm"
        modalClassName="bd-example-modal-sm"
        onClick={() => props.setSmallModal(false)}
      >
        <div className="modal-header">
          <h4 className="modal-title" id="mySmallModalLabel">
            Partager ma carte
          </h4>
          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={() => props.setSmallModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody style={{ textAlign: "center" }}>
          <QRCode
            value={props.qrcode}
            bgColor="black"
            fgColor="white"
          />
          <Button onClick={props.sendEmail}>Partager par email</Button>
        </ModalBody>
      </Modal>
    )
}

export default Share