import React, { useState } from "react";
import { Card, CardHeader, Button, Input, Form } from "reactstrap";

const Mailchange = (props) => {
  const [email, SetEmail] = useState("");
  const [new_email, SetNewEmail] = useState("");

  return (
    <Form onSubmit={(e) => props.onAdd(e, [email,new_email])}>
      <Card className="p-3">
        <CardHeader>Modifier adresse e-mail</CardHeader>
        <Input className="mb-1"
          placeholder="Email actuel"
          value={email}
          onChange={(e) => SetEmail(e.target.value)}
        />
        <Input
          placeholder="Update Email"
          value={new_email}
          onChange={(e) => SetNewEmail(e.target.value)}
        />
        <Button className="mt-2">Update</Button>
      </Card>
    </Form>
  );
};

export default Mailchange;
