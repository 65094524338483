import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import Feed from "../components/Feed/Feed";
import FooterDefault from "../components/Footers/DefaultFooter";
import HomeHeaders from "../components/Headers/HomeHeaders";
import NavBar from "../components/Navbar/Navbar";

import "../assets/css/Homepage.css";
import Features from "../components/Features";

const Homepage = (props) => {
  let history = useHistory();
  return (
    <>
      <NavBar />
      <div className="wrapper">
        <HomeHeaders />
      </div>
      <div className="my-3">
        <div>
          <Feed />
        </div>
      </div>
      <div>
        <Features/>
      </div>
      <FooterDefault />
    </>
  );
};

export default Homepage;
