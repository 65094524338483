import React from "react";

const ExpertisesInfos = (props) => {
  return (
    <div>
      <div
        className="p-2"
        style={{ backgroundColor: props.color, color: "white" }}
      >
        Historiques & Expertises
      </div>
      <div className="p-3" style={{whiteSpace: "pre-wrap"}}>
        {props.expertises}
      </div>
    </div>
  );
};

export default ExpertisesInfos;
