import React from 'react'
import { Col, Container, Row } from 'reactstrap'

const Features = (props) => {
    return (
        <div className="cd-section" id="features">
        <div className="features-1">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">Nos caractéristiques</h2>
                <h4 className="description">
                  Créer votre carte de visite est essentiel pour votre buisness.
                  Elle permet de vous connecter avec vos clients et même de convertir votre prospect.
                  Découvrez comment notre carte de visite peut vous aider dans votre quotidien.
                </h4>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-info">
                    <i className="now-ui-icons arrows-1_cloud-upload-94"></i>
                  </div>
                  <h4 className="info-title">Télécharger votre logo &amp; votre photo de profil</h4>
                </div>
              </Col>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-success">
                    <i className="now-ui-icons design_palette"></i>
                  </div>
                  <h4 className="info-title">Personnalisation des couleurs</h4>
                </div>
              </Col>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-warning">
                    <i className="now-ui-icons design-2_ruler-pencil"></i>
                  </div>
                  <h4 className="info-title">Design Responsive</h4>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-warning">
                    <i className="now-ui-icons travel_info"></i>
                  </div>
                  <h4 className="info-title">Url unique</h4>
                </div>
              </Col>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-info">
                    <i className="now-ui-icons users_single-02"></i>
                  </div>
                  <h4 className="info-title">Informations de contact</h4>
                </div>
              </Col>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-success">
                    <i className="now-ui-icons ui-1_send"></i>
                  </div>
                  <h4 className="info-title">Partager par QRCode &amp; Email </h4>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        </div>
    )
}

export default Features