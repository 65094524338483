import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import Photo from "../../assets/images/user.svg";

const CDVList = ({ filtered = [] }) => {
  return (
    <>
      <Row style={{ display: "flex" }} className="px-3">
        {filtered.map((e) => (
          <Col>
            <Card className="card-profile" data-background-color="black">
              <div className="card-avatar">
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <img alt="..." className="img img-raised" src={Photo}></img>
                </a>
              </div>
              <CardBody>
                <h6 className="card-category">
                  CEO / Co-Founder • {e.societe}
                </h6>
                <CardTitle tag="h4">
                  {e.firstname} {e.lastname}
                </CardTitle>
                <p className="card-description">
                  {e.phone} {e.address}
                </p>
                <Link
                  to={`/${e.poste.toLowerCase()}-${e.lastname.toLowerCase()}`}
                >
                  <Button className="btn-round" color="primary">
                    Voir la carte
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default CDVList;
