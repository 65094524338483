import React from "react";
import { Col, Row } from "reactstrap";
import CarouselDefault from "../../Carousel";

const SocieteInfos = (props) => {
  return (
    <>
      <div
        className="p-2"
        style={{ backgroundColor: props.color, color: "white" }}
      >
        {props.societe}
      </div>
      <Row >
        <Col >
          <a href={props.website} >
            <img alt={`Logo_${props.societe}`} src={props.logoCompany}  />
          </a>
        </Col>
        <Col style={{display: "flex"}}>
          <h4
            className="info-title"
            style={{
              margin: "auto",
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            {props.societe} <br /> {props.metier}
          </h4>
        </Col>
      </Row>
      
    </>
  );
};
/*
<div className="p-3">
        <CarouselDefault carouselPicture={props.carouselPicture}/>
      </div>
*/
export default SocieteInfos;
