import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const Dropdown = (props) => {
  let history = useHistory();
  const [open, setOpen] = useState(false);

  return (
    <div>
      <ButtonDropdown isOpen={open} toggle={() => setOpen(!open)}>
        <DropdownToggle caret className="my_btn" color="primary">
          Bonjour, {props.title}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={(e) => history.push("/profil")}>
            Informations utilisateurs
          </DropdownItem>
          <DropdownItem
            onClick={(e) =>
              history.push("/" + props.username)
            }
          >
            Cartes de visite
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem
            onClick={(e) => {
              props.Onlogout();
              history.push("/");
            }}
          >
            Deconnexion
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </div>
  );
};

export default Dropdown;
