/*eslint-disable*/
import React from "react";
import { useHistory } from "react-router-dom";

// reactstrap components
import { Container } from "reactstrap";

// core components

function FooterDefault() {
  let history = useHistory();
  return (
    <>
      <footer className="footer footer-default" style={{position: "fixed", bottom: 0, textAlign: "center", width: "100%"}}>
        <Container>
          <nav>
            <ul>
              <li>
                <a href="#" onClick={() => history.push('/')}>
                  TECHTONUS
                </a>
              </li>
              <li>
                <a href="#login" onClick={() => history.push('/login')}>
                  Se connecter
                </a>
              </li>
              <li>
                <a href="#inscription" onClick={() => history.push('/inscription')}>
                  S'inscrire
                </a>
              </li>
            </ul>
          </nav>
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()}, Product of{" "}
            <a href="https://asig-tech.com" target="_blank">
              IME
            </a>
            . Coded by{" "}
            <a href="https://asig-tech.com" target="_blank">
              ASIG-Tech
            </a>
            .
          </div>
        </Container>
      </footer>
    </>
  );
}

export default FooterDefault;
