import React from "react";

const HorairesTarifsInfos = (props) => {
  return (
    <div>
      <div
        className="p-2"
        style={{ backgroundColor: props.color, color: "white" }}
      >
        Horaires & Tarifs
      </div>
      <div className="p-3" style={{ display: "flex" }}>
        <img
          alt="..."
          src={require("../../../assets/images/calendar.svg").default}
          width="20%"
        />
        <div style={{ margin: "auto" }}>
          <div style={{ display: "flex" }}>
            <p style={{ fontSize: 12, fontWeight: 600, marginBottom: 0 }}>
              Lundi:
            </p>
            <p
              style={{
                fontSize: 12,
                fontWeight: 400,
                marginBottom: 0,
                paddingLeft: 10,
              }}
            >
              {props.lundi}
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <p style={{ fontSize: 12, fontWeight: 600, marginBottom: 0 }}>
              Mardi:
            </p>
            <p
              style={{
                fontSize: 12,
                fontWeight: 400,
                marginBottom: 0,
                paddingLeft: 10,
              }}
            >
              {props.mardi}
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <p style={{ fontSize: 12, fontWeight: 600, marginBottom: 0 }}>
              Mercredi:
            </p>
            <p
              style={{
                fontSize: 12,
                fontWeight: 400,
                marginBottom: 0,
                paddingLeft: 10,
              }}
            >
              {props.mercredi}
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <p style={{ fontSize: 12, fontWeight: 600, marginBottom: 0 }}>
              Jeudi:
            </p>
            <p
              style={{
                fontSize: 12,
                fontWeight: 400,
                marginBottom: 0,
                paddingLeft: 10,
              }}
            >
              {props.jeudi}
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <p style={{ fontSize: 12, fontWeight: 600, marginBottom: 0 }}>
              Vendredi:
            </p>
            <p
              style={{
                fontSize: 12,
                fontWeight: 400,
                marginBottom: 0,
                paddingLeft: 10,
              }}
            >
              {props.vendredi}
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <p style={{ fontSize: 12, fontWeight: 600, marginBottom: 0 }}>
              Samedi:
            </p>
            <p
              style={{
                fontSize: 12,
                fontWeight: 400,
                marginBottom: 0,
                paddingLeft: 10,
              }}
            >
              {props.samedi}
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <p style={{ fontSize: 12, fontWeight: 600, marginBottom: 0 }}>
              Dimanche:
            </p>
            <p
              style={{
                fontSize: 12,
                fontWeight: 400,
                marginBottom: 0,
                paddingLeft: 10,
              }}
            >
              {props.dimanche}
            </p>
          </div>
        </div>
      </div>

      <div className="p-3" style={{ display: "flex" }}>
        <img
          alt="..."
          src={require("../../../assets/images/price.svg").default}
          width="20%"
        />
        <div style={{ margin: "auto" }}>
          <div style={{ display: "flex", whiteSpace: "pre-wrap" }}>
            {props.prestations}
           </div>
           </div>
      </div>
    </div>
  );
};

export default HorairesTarifsInfos;
