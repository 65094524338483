import React, { useState } from 'react';
import {Button} from  'reactstrap';

const PasswordForgotten = (props) => {
    const [password, setPassword] = useState();
    console.log(props.match.params.email)
    const changePassword = () => {
      
        fetch(
            "https://apicdv.asig-tech.com/auth/resetpassword",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                email:  props.match.params.email,
                password: password,
              })
            }
          )
            .then((res) => {
              if (res.status !== 200) {
                throw new Error("Failed to fetch user status.");
              }
              return res.json();
            })
            .then((resData) => {
              console.log(resData);
            })
            .catch((err) => console.log(err));
        }

    return(
        <div>
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
            <Button onClick={changePassword}>Reset password</Button>
        </div>
    )
}

export default PasswordForgotten;