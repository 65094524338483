import React from "react";

import { Badge, Container } from "reactstrap";
import "../../assets/css/HomeHeaders.css";
import ProfilePicture from "../../assets/images/loic.jpg";
const ProfileHeaders = (props) => {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <>
      <div
        className="page-header clear-filter page-header-small"
        
      >
        <div className="page-header-image h-i" ref={pageHeader} style={{zIndex: 1}}></div>
        <Container>
          <div className="photo-container" style={{zIndex: 10}}>
            <img alt="..." src={props.profileImage}></img>
          </div>
          <h3 className="title">
            {props.firstname} {props.lastname}
          </h3>

        </Container>
      </div>
    </>
  );
};

export default ProfileHeaders;
