import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Card } from "reactstrap";

import axios from "axios";

import "../../assets/css/HomeHeaders.css";
import CDV from "../CDV/CDV";
import FooterDefault from "../Footers/DefaultFooter";
import NavBar from "../Navbar/Navbar";
import CreateCdv from "../Wizard/CreateCdv";
import Fond from "../../assets/images/factory40.jpg";
import "../../assets/css/CreateCdv.css";

const SignUp = (props) => {
  let history = useHistory();

  const onSubmit = async (file1, file2) => {
    console.log(file1);
    console.log(file2);
    const formData = new FormData();
    formData.append("file", file1);
    formData.append("fileName", file1.name);
    try {
      const res = await axios.post("https://apicdv.asig-tech.com/upload", formData);
      console.log(res);
    } catch (ex) {
      console.log(ex);
    }

    const formData2 = new FormData();
    formData2.append("file", file2);
    formData2.append("fileName", file2.name);
    try {
      const res = await axios.post("https://apicdv.asig-tech.com/upload", formData2);
      console.log(res);
    } catch (ex) {
      console.log(ex);
    }
  };

  const [email, setEmail] = useState("");
  const [lastname, SetLastname] = useState("");
  const [firstname, SetFirstname] = useState("");
  const [societe, setSociete] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const [position, setPosition] = useState("");

  const [address, setAddress] = useState("");
  const [postalCode, setpostalCode] = useState("");
  const [ville, setVille] = useState("");
  const [pays, setPays] = useState("");

  const [colorback, setColorBack] = useState("white");
  const [colorfront, setColorFront] = useState("orange");
  const [colortext, setColorText] = useState("black");

  const [expertises, setExpertises] = useState("");

  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [linkedin, setLinkedin] = useState("");

  const [website, setWebsite] = useState("");

  const [prestations, setPrestation] = useState("");

  const [lundi, setLundi] = useState("");
  const [mardi, setMardi] = useState("");
  const [mercredi, setMercredi] = useState("");
  const [jeudi, setJeudi] = useState("");
  const [vendredi, setVendredi] = useState("");
  const [samedi, setSamedi] = useState("");
  const [dimanche, setDimanche] = useState("");

  const [profileImage, setProfileImage] = useState();
  const [logoCompany, setLogoCompany] = useState();

  const [previewPi, setPreviewPi]  = useState();
  const [previewLc, setPreviewLc]  = useState();

  const [carouselPicture, setCarouselPicture] = useState("");

  React.useEffect(() => {
    document.body.classList.add("signup-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("signup-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <>
      <NavBar />
      <div className="body_cdv">
        <div className="create_cdv">
          <CreateCdv
            profileImage={profileImage}
            setPreviewPi={setPreviewPi}
            setProfileImage={setProfileImage}
            setPreviewLc={setPreviewLc}
            logoCompany={logoCompany}
            setLogoCompany={setLogoCompany}
            carouselPicture={carouselPicture}
            setCarouselPicture={setCarouselPicture}
            email={email}
            setEmail={setEmail}
            lastname={lastname}
            SetLastname={SetLastname}
            firstname={firstname}
            SetFirstname={SetFirstname}
            societe={societe}
            setSociete={setSociete}
            phone={phone}
            setPhone={setPhone}
            password={password}
            setPassword={setPassword}
            position={position}
            setPosition={setPosition}
            address={address}
            setAddress={setAddress}
            postalCode={postalCode}
            setpostalCode={setpostalCode}
            ville={ville}
            setVille={setVille}
            pays={pays}
            setPays={setPays}
            website={website}
            setWebsite={setWebsite}
            instagram={instagram}
            setInstagram={setInstagram}
            facebook={facebook}
            setFacebook={setFacebook}
            twitter={twitter}
            setTwitter={setTwitter}
            linkedin={linkedin}
            setLinkedin={setLinkedin}
            expertises={expertises}
            setExpertises={setExpertises}
            prestations={prestations}
            setPrestation={setPrestation}
            lundi={lundi}
            setLundi={setLundi}
            mardi={mardi}
            setMardi={setMardi}
            mercredi={mercredi}
            setMercredi={setMercredi}
            jeudi={jeudi}
            setJeudi={setJeudi}
            vendredi={vendredi}
            setVendredi={setVendredi}
            samedi={samedi}
            setSamedi={setSamedi}
            dimanche={dimanche}
            setDimanche={setDimanche}
            colortext={colortext}
            setColorText={setColorText}
            colorback={colorback}
            setColorBack={setColorBack}
            colorfront={colorfront}
            setColorFront={setColorFront}
            onAdd={(e, data) => props.onSignup(e, data)}
            onSubmit={(e, profile, company) => onSubmit(profile, company) }
          />
        </div>
        <div className="cdv_card">
          <CDV
            carouselPicture={carouselPicture}
            logoCompany={previewLc}
            profileImage={previewPi}
            colorfront={colorfront}
            colorback={colorback}
            colortext={colortext}
            lundi={lundi}
            mardi={mardi}
            mercredi={mercredi}
            jeudi={jeudi}
            vendredi={vendredi}
            samedi={samedi}
            dimanche={dimanche}
            prestations={prestations}
            societe={societe}
            firstname={firstname}
            lastname={lastname}
            phone={phone}
            email={email}
            address={address}
            metier={position}
            postal_code={postalCode}
            city={ville}
            country={pays}
            website={website}
            instagram={instagram}
            facebook={facebook}
            twitter={twitter}
            linkedin={linkedin}
            expertises={expertises}
            username={`${position.toLowerCase()}-${lastname.toLowerCase()}`}
          />
        </div>
      </div>
      <div
        className="page-header  header-filter"
        filter-color="black"
        style={{ display: "block", position: "absolute" }}
      >
        <div
          className="page-header-image login-back"
          style={{ position: "absolute" }}
        ></div>
      </div>

      <FooterDefault />
    </>
  );
};

export default SignUp;
