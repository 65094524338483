import React, { useState } from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import "./CDV.css";
import LogoTest from "../../assets/images/logo.png";
import ProfileHeaders from "../Headers/ProfileHeaders";
import InfosContact from "./elements/Contact";
import SocieteInfos from "./elements/Societe";
import ExpertisesInfos from "./elements/Expertises";
import HorairesTarifsInfos from "./elements/HorairesTarifs";
import Share from "./elements/Share";
import ModalContact from "./elements/ModalContact";
import QRCode from "qrcode.react";


const CDV = (props) => {
  const [smallModal, setSmallModal] = useState(false);
  const [ContactModal, setContactModal] = useState(false);

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  React.useEffect(() => {
    if (props.prod === "1")
    {
      haveVCard()
    }
    
  }, [props.email])

  const haveVCard = async () => {
    fetch("https://apicdv.asig-tech.com/auth/vcard", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: props.email,
        lastname: props.lastname,
        firstname: props.firstname,
        societe: props.societe,
        phone: props.phone,
        address: props.address,
        poste: props.poste,
        postal_code: props.postal_code,
        city: props.city,
        country: props.country,
        website: props.website,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        console.log(resData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="wrapper">
        <ProfileHeaders
          profileImage={props.profileImage}
          lastname={props.lastname}
          firstname={props.firstname}
          societe={props.societe}
          poste={props.poste}
          email={props.email}
          phone={props.phone}
        />
        <div
          className="section"
          style={{ color: props.colortext, backgroundColor: props.colorback }}
        >
          <div className="button-container">
            <Button
              className="btn-round mr-1"
              color="info"
              onClick={(e) => setContactModal(true)}
              size="lg"
            >
              Me contacter
            </Button>
            {props.facebook ? (
              <>
                <Button
                  className="btn-round btn-icon mr-1"
                  color="default"
                  href={props.facebook}
                  id="tooltip871723215"
                  size="lg"
                >
                  <i className="fab fa-facebook"></i>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip871723215">
                  Suivez-moi sur Facebook
                </UncontrolledTooltip>
              </>
            ) : null}
            {props.linkedin ? (
              <>
                <Button
                  className="btn-round btn-icon mr-1"
                  color="default"
                  href={props.linkedin}
                  id="tooltip871723219"
                  size="lg"
                >
                  <i className="fab fa-linkedin"></i>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip871723219">
                  Suivez-moi sur Linkedin
                </UncontrolledTooltip>
              </>
            ) : null}
            {props.twitter ? (
              <>
                <Button
                  className="btn-round btn-icon mr-1"
                  color="default"
                  href={props.twitter}
                  id="tooltip871723210"
                  size="lg"
                >
                  <i className="fab fa-twitter"></i>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip871723210">
                  Suivez-moi sur Twitter
                </UncontrolledTooltip>
              </>
            ) : null}
            {props.instagram ? (
              <>
                <Button
                  className="btn-round btn-icon mr-1"
                  color="default"
                  href={props.instagram}
                  id="tooltip259363830"
                  size="lg"
                >
                  <i className="fab fa-instagram"></i>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip259363830">
                  Suivez-moi sur Instagram
                </UncontrolledTooltip>
              </>
            ) : null}

            <Button
              className="btn-round btn-icon mr-1"
              color="danger"
              id="tooltip2556465"
              onClick={() => setSmallModal(true)}
              size="lg"
            >
              <i class="fas fa-share-alt"></i>
            </Button>
            <UncontrolledTooltip delay={0} target="tooltip2556465">
              Partager
            </UncontrolledTooltip>
          </div>
          <InfosContact
            phone={props.phone}
            email={props.email}
            address={props.address}
            postal_code={props.postal_code}
            city={props.city}
            country={props.country}
          />
          <SocieteInfos
            logoCompany={props.logoCompany}
            societe={props.societe}
            carouselPicture={props.carouselPicture}
            website={props.website}
            metier={props.metier}
            color={props.colorfront}
          />
          <ExpertisesInfos
            expertises={props.expertises}
            color={props.colorfront}
          />
          <HorairesTarifsInfos
            prestations={props.prestations}
            color={props.colorfront}
            lundi={props.lundi}
            mardi={props.mardi}
            mercredi={props.mercredi}
            jeudi={props.jeudi}
            vendredi={props.vendredi}
            samedi={props.samedi}
            dimanche={props.dimanche}
          />
          <div style={{ textAlign: "center" }}>
            <p>Partager votre carte</p>
            <QRCode
              value={`https://apicdv.asig-tech.com/vcard/${props.firstname}-${props.lastname}.vcf`}
              bgColor={props.colorback || "black"}
              fgColor={props.colortext || "white"}
            />
            <br />
            <button
              onClick={() => {
                haveVCard().then(() =>
                {
                  window.location.href = `https://apicdv.asig-tech.com/vcard/${props.firstname}-${props.lastname}.vcf`
                })
                
              }}
              target="_blank"
            >
              Enregistrer le contact
            </button>
          </div>
        </div>
      </div>
      <Share smallModal={smallModal} setSmallModal={setSmallModal} sendEmail={props.sendEmail} qrcode={`https://apicdv.asig-tech.com/vcard/${props.firstname}-${props.lastname}.vcf`}/>
      <ModalContact
        ContactModal={ContactModal}
        setContactModal={setContactModal}
        lastname={props.lastname}
        firstname={props.firstname}
        email={props.email}
        phone={props.phone}
        address={props.address}
        postal_code={props.postal_code}
        city={props.city}
        country={props.country}
      />
    </>
  );
};

export default CDV;
