import React, { useState } from "react";
import { CompactPicker } from "react-color";
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  CardFooter,
  Button,
  Input,
  FormGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import UploadImage from "../UploadImage";


const CreateCdv = (props) => {
  const [iconTabs, setIconTabs] = useState("1");
  const [buttonText, setButtonText] = useState("Suivant");

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <Nav className="justify-content-center" role="tablist" tabs>
                  <NavItem>
                    <NavLink
                      className={iconTabs === "1" ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setButtonText("Suivant");
                        setIconTabs("1");
                      }}
                    >
                      <i className="now-ui-icons objects_umbrella-13"></i>
                      Vous
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={iconTabs === "2" ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setButtonText("Suivant");
                        setIconTabs("2");
                      }}
                    >
                      <i className="now-ui-icons shopping_cart-simple"></i>
                      Votre société
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={iconTabs === "3" ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setButtonText("Suivant");
                        setIconTabs("3");
                      }}
                    >
                      <i className="now-ui-icons shopping_shop"></i>
                      Informations utiles
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={iconTabs === "4" ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setButtonText("Suivant");
                        setIconTabs("4");
                      }}
                    >
                      <i className="now-ui-icons ui-2_settings-90"></i>
                      Couleurs
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={iconTabs === "5" ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setButtonText("Suivant");
                        setIconTabs("5");
                      }}
                    >
                      <i className="now-ui-icons ui-2_settings-90"></i>
                      Images
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={iconTabs === "6" ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setButtonText("Envoyer");
                        setIconTabs("6");
                      }}
                    >
                      <i className="now-ui-icons ui-2_settings-90"></i>
                      Réseaux Sociaux
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <CardBody>
                <TabContent
                  className="text-center"
                  activeTab={"iconTabs" + iconTabs}
                >
                  <TabPane tabId="iconTabs1">
                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <Input
                          className="mb-1"
                          type="text"
                          placeholder="Prénom"
                          value={props.firstname}
                          onChange={(e) => props.SetFirstname(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <Input
                          type="text"
                          className="mb-1"
                          placeholder="Nom"
                          value={props.lastname}
                          onChange={(e) => props.SetLastname(e.target.value)}
                        />
                      </FormGroup>
                    </div>
                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <Input
                          type="email"
                          className="mb-1"
                          placeholder="Adresse email"
                          value={props.email}
                          onChange={(e) => props.setEmail(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <Input
                          type="password"
                          className="mb-1"
                          placeholder="Mot de passe"
                          value={props.password}
                          onChange={(e) => props.setPassword(e.target.value)}
                        />
                      </FormGroup>
                    </div>

                    <FormGroup>
                      <Input
                        type="number"
                        className="mb-1"
                        placeholder="Numéro de téléphone"
                        value={props.phone}
                        onChange={(e) => props.setPhone(e.target.value)}
                      />
                    </FormGroup>
                  </TabPane>
                  <TabPane tabId="iconTabs2">
                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <Input
                          className="mb-1"
                          type="text"
                          placeholder="Nom de la société"
                          value={props.societe}
                          onChange={(e) => props.setSociete(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <Input
                          type="text"
                          className="mb-1"
                          placeholder="Votre métier"
                          value={props.position}
                          onChange={(e) => props.setPosition(e.target.value)}
                        />
                      </FormGroup>
                    </div>
                    <FormGroup>
                      <Input
                        className="mb-1"
                        type="text"
                        placeholder="Site web"
                        value={props.website}
                        onChange={(e) => props.setWebsite(e.target.value)}
                      />
                    </FormGroup>
                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <Input
                          type="text"
                          className="mb-1"
                          placeholder="Adresse"
                          value={props.address}
                          onChange={(e) => props.setAddress(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <Input
                          type="text"
                          className="mb-1"
                          placeholder="Code postal"
                          value={props.postalCode}
                          onChange={(e) => props.setpostalCode(e.target.value)}
                        />
                      </FormGroup>
                    </div>

                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <Input
                          type="text"
                          className="mb-1"
                          placeholder="Ville"
                          value={props.ville}
                          onChange={(e) => props.setVille(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <Input
                          type="text"
                          className="mb-1"
                          placeholder="Pays"
                          value={props.pays}
                          onChange={(e) => props.setPays(e.target.value)}
                        />
                      </FormGroup>
                    </div>
                  </TabPane>
                  <TabPane tabId="iconTabs3">
                    <h4>Vos expertises/historiques</h4>
                    <textarea
                      rows="5"
                      cols="100"
                      placeholder="Vos expertises/historiques"
                      value={props.expertises}
                      onChange={(e) => props.setExpertises(e.target.value)}
                    ></textarea>
                    <h4>Vos horaires</h4>
                    <Input
                      placeholder="Horaires Lundi"
                      value={props.lundi}
                      onChange={(e) => props.setLundi(e.target.value)}
                    />
                    <Input
                      placeholder="Horaires Mardi"
                      value={props.mardi}
                      onChange={(e) => props.setMardi(e.target.value)}
                    />
                    <Input
                      placeholder="Horaires Mercredi"
                      value={props.mercredi}
                      onChange={(e) => props.setMercredi(e.target.value)}
                    />
                    <Input
                      placeholder="Horaires Jeudi"
                      value={props.jeudi}
                      onChange={(e) => props.setJeudi(e.target.value)}
                    />
                    <Input
                      placeholder="Horaires Vendredi"
                      value={props.vendredi}
                      onChange={(e) => props.setVendredi(e.target.value)}
                    />
                    <Input
                      placeholder="Horaires Samedi"
                      value={props.samedi}
                      onChange={(e) => props.setSamedi(e.target.value)}
                    />
                    <Input
                      placeholder="Horaires Dimanche"
                      value={props.dimanche}
                      onChange={(e) => props.setDimanche(e.target.value)}
                    />
                    <h4>Vos tarifs/prestation</h4>
                    <textarea
                      rows="5"
                      cols="100"
                      placeholder="Vos tarifs/prestations"
                      value={props.prestation}
                      onChange={(e) => props.setPrestation(e.target.value)}
                    ></textarea>
                  </TabPane>
                  <TabPane tabId="iconTabs4">
                    <div className="form-row">
                      <FormGroup className="col-md-3">
                        <h3>Couleur d'accent</h3>
                        <CompactPicker
                          color={props.colorfront}
                          onChange={(e) => {
                            props.setColorFront(e.hex);
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="col-md-3">
                        <h3>Couleur de fond</h3>
                        <CompactPicker
                          color={props.colorback}
                          onChange={(e) => {
                            props.setColorBack(e.hex);
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="col-md-3">
                        <h3>Couleur de texte</h3>
                        <CompactPicker
                          color={props.colortexte}
                          onChange={(e) => {
                            props.setColorText(e.hex);
                          }}
                        />
                      </FormGroup>
                    </div>
                  </TabPane>
                  <TabPane tabId="iconTabs5">
                    <div className="form-row">
                      <div className="col-md-4">
                        <h5>Photo de profil</h5>
                        <UploadImage profileImage={props.profileImage} setProfileImage={(file) => {
                          props.setProfileImage(file)
                          props.setPreviewPi(URL.createObjectURL(file))
                          }} info={"profile"}/>
                      </div>
                      <div className="col-md-4">
                        <h5>Logo de l'entreprise</h5>
                        <UploadImage profileImage={props.logoCompany} setProfileImage={(file) => {
                          props.setLogoCompany(file) 
                          props.setPreviewLc(URL.createObjectURL(file))
                          }} info={"logo"}/>
                      </div>
                      
                    </div>
                  </TabPane>
                  <TabPane tabId="iconTabs6">
                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <Input
                          className="mb-1"
                          type="text"
                          placeholder="Instagram"
                          value={props.instagram}
                          onChange={(e) => props.setInstagram(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <Input
                          type="text"
                          className="mb-1"
                          placeholder="Facebook"
                          value={props.facebook}
                          onChange={(e) => props.setFacebook(e.target.value)}
                        />
                      </FormGroup>
                    </div>
                    <div className="form-row">
                      <FormGroup className="col-md-6">
                        <Input
                          className="mb-1"
                          type="text"
                          placeholder="Twitter"
                          value={props.twitter}
                          onChange={(e) => props.setTwitter(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup className="col-md-6">
                        <Input
                          type="text"
                          className="mb-1"
                          placeholder="Linkedin"
                          value={props.linkedin}
                          onChange={(e) => props.setLinkedin(e.target.value)}
                        />
                      </FormGroup>
                    </div>
                  </TabPane>
                </TabContent>
              </CardBody>
              <CardFooter style={{ textAlign: "center" }}>
                <Button
                  onClick={(e) => {
                    if (iconTabs === "5") {
                      setButtonText("Envoyer");
                      setIconTabs((parseInt(iconTabs) + 1).toString());
                    } else if (iconTabs === "6") {
                      setButtonText("Suivant");
                      console.log("SendData");
                      props.onSubmit(e, props.profileImage, props.logoCompany)
                      props.onAdd(e, [
                        props.email,
                        props.password,
                        props.firstname,
                        props.lastname,
                        props.societe,
                        props.phone,
                        props.address,
                        props.position,
                        props.postalCode,
                        props.ville,
                        props.pays,
                        props.website,
                        props.logoCompany,
                        props.profileImage,
                        props.lundi,
                        props.mardi,
                        props.mercredi,
                        props.jeudi,
                        props.vendredi,
                        props.samedi,
                        props.dimanche,
                        props.prestations,
                        props.colorfront,
                        props.expertises,
                        props.instagram,
                        props.facebook,
                        props.twitter,
                        props.linkedin,
                        props.colorback,
                        props.colortext,
                      ]);
                    } else {
                      setButtonText("Suivant");
                      setIconTabs((parseInt(iconTabs) + 1).toString());
                    }
                  }}
                >
                  {buttonText}
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateCdv;
