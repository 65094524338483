import React, { useState } from "react";
import axios from "axios";
import default_image from "../assets/images/dl.svg";

const UploadImage = (props) => {
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [preview, setPreview] = useState(default_image);

  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const uploadFile = async (e) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);
    try {
      const res = await axios.post("http://localhost:8080/upload", formData);
      props.setProfileImage(`/images/${fileName}`);
      console.log(res);
    } catch (ex) {
      console.log(ex);
    }
  };

  return (
    <div className="App">
      <input type="file" onChange={(e) => {
        saveFile(e)
        props.setProfileImage(e.target.files[0]);
        setPreview(URL.createObjectURL(e.target.files[0]))
      }
        } />
      <img src={preview} alt="..."/>
      <button
        onClick={() => {
          uploadFile();
          props.setProfileImage(file);
        }}
      >
        Upload
      </button>
    </div>
  );
};

export default UploadImage;
